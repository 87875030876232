import { Button, ChipIconButton } from '@coop/components';
import { CloseIcon } from '@coop/icons';
import classNames from 'classnames';
import type { FC } from 'react';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { appConfig } from '../../../../appConfig';
import { Cookies } from '../../../../utility/cookies';
import { COOKIE_LOGIN_REMINDER } from '../../../common/persistance/cookie/cookieKeys';
import { ga4UserTracking } from '../../../common/tracking/ga4/user';
import Tracking from '../../../common/tracking/tracking';
import { getCreateAccountUrl, getLoginUrlWithReturnUrl } from '../../../common/utils/urlUtils';
import styles from './LoginReminderTooltip.module.less';

interface LoginReminderTooltipProps {
    stayAfterLoginLogoutRedirect: boolean;
}

const LoginReminderTooltip: FC<React.PropsWithChildren<LoginReminderTooltipProps>> = (props) => {
    const [show, setShow] = useState(false);
    const transitionRef = useRef(null);

    useEffect(() => {
        const isUserLoggedIn = appConfig.coopUserSettings.isAuthenticated;
        const hasSeenTooltipThisSession = () =>
            !!Cookies.read(COOKIE_LOGIN_REMINDER) ||
            window.abtest.loginReminderPopupShown === false;

        let handle: NodeJS.Timeout;
        if (!isUserLoggedIn && !hasSeenTooltipThisSession()) {
            handle = setTimeout(() => {
                if (!hasSeenTooltipThisSession()) {
                    setShow(true);
                }
            }, 40000);
        }
        return () => {
            clearTimeout(handle);
        };
    }, []);

    const close = () => {
        Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
        Tracking.header.loginReminder.close();

        setShow(false);
    };

    const loginUrl = getLoginUrlWithReturnUrl(props.stayAfterLoginLogoutRedirect);

    const createAccountUrl = getCreateAccountUrl();

    return (
        <CSSTransition
            nodeRef={transitionRef}
            in={show}
            timeout={200}
            classNames={{
                enterActive: styles['is-visible'],
                enterDone: styles['is-visible'],
            }}
            unmountOnExit
        >
            <div
                ref={transitionRef}
                role="tooltip"
                className={classNames(styles.LoginReminderWrapper, styles['is-visible'])}
            >
                <div className={styles.LoginReminder}>
                    <div className={styles.Heading}>Hej!</div>
                    <div className={styles.Content}>
                        Glöm inte att logga in för att se erbjudanden i ditt område.
                    </div>
                    <div className={styles.Close}>
                        <ChipIconButton
                            theme="whiteGreen"
                            onClick={close}
                            size={32}
                            icon={CloseIcon}
                            label="Stäng"
                        />
                    </div>
                    <div className="u-flex u-flexGap8">
                        <Button theme="primary" asChild>
                            <a
                                href={loginUrl}
                                onClick={(e) => {
                                    e.preventDefault();
                                    Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
                                    Tracking.header.loginReminder.goToLogin();
                                    ga4UserTracking.login(null, 'start');
                                    window.location.href = e.currentTarget.href;
                                }}
                            >
                                Logga in
                            </a>
                        </Button>
                        <Button theme="primaryOutline" asChild>
                            <a
                                href={createAccountUrl}
                                onClick={() => {
                                    Cookies.create(COOKIE_LOGIN_REMINDER, 'true');
                                    Tracking.header.loginReminder.goToCreateAccount();
                                }}
                            >
                                Skapa konto
                            </a>
                        </Button>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default LoginReminderTooltip;
