import { Icon } from '@coop/components';
import { useSetTimeout } from '@coop/hooks';
import { ChevronDownIcon, CloseIcon } from '@coop/icons';
import classNames from 'classnames';
import type { ReactNode } from 'react';
import * as React from 'react';
import { useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { NoticeRibbonType } from './NoticeRibbon.types';

type NotceRibbonAlign = 'left' | 'center';

type NoticeRibbonColor = 'accentPink';

interface NoticeRibbonProps {
    show: boolean;
    type?: NoticeRibbonType;
    animated?: boolean;
    extraCss?: string;
    onClick?: () => void;
    handleCloseClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    hideAfterMiliseconds?: number; // set null if you want to disable automatic hide
    children?: React.ReactNode;
    expandableContent?: ReactNode;
    blockClose?: boolean;
    fixed?: boolean;
    align?: NotceRibbonAlign;
    color?: NoticeRibbonColor;
    rounded?: boolean;
}

const NoticeRibbon = ({
    align = 'center',
    animated = true,
    ...props
}: React.PropsWithChildren<NoticeRibbonProps>) => {
    const containerStyles = classNames(
        'Notice',
        props.type === NoticeRibbonType.warning && 'Notice--warning',
        props.type === NoticeRibbonType.info && 'Notice--info',
        props.type === NoticeRibbonType.success && 'Notice--success',
        props.type === NoticeRibbonType.editOrder && 'Notice--yellow',
        props.type === NoticeRibbonType.alertLevel1 && 'Notice--alert Notice--alert-level1',
        props.type === NoticeRibbonType.alertLevel2 && 'Notice--alert Notice--alert-level2',
        props.type === NoticeRibbonType.alertLevel3 && 'Notice--alert Notice--alert-level3',
        props.type === NoticeRibbonType.alertLevel4 && 'Notice--alert Notice--alert-level4',
        animated && 'Notice--animated',
        props.fixed && 'Notice--fixed',
        props.extraCss,
        props.onClick && 'u-cursorPointer',
        props.color && `Notice--${props.color}`,
        props.rounded && 'Notice--rounded',
    );

    const transitionRef = useRef(null);

    const [isExpanded, setIsExpanded] = useState(false);

    useSetTimeout(
        props.show && !!props.hideAfterMiliseconds && !!props.handleCloseClick,
        props.hideAfterMiliseconds!,
        () => props.handleCloseClick?.(),
    );

    const Content = useMemo(
        () => (
            <NoticeRibbonContent
                ref={transitionRef}
                containerStyles={containerStyles}
                onClick={props.onClick}
                handleCloseClick={!props.blockClose ? props.handleCloseClick : undefined}
                isExpanded={isExpanded}
                onExpandClick={() => setIsExpanded((prev) => !prev)}
                expandableContent={props.expandableContent}
                align={align}
            >
                {props.children}
            </NoticeRibbonContent>
        ),
        [
            containerStyles,
            isExpanded,
            align,
            props.blockClose,
            props.children,
            props.expandableContent,
            props.handleCloseClick,
            props.onClick,
        ],
    );

    if (!animated) {
        if (props.show) {
            return Content;
        }
        return null;
    }

    return (
        <CSSTransition
            nodeRef={transitionRef}
            in={props.show}
            timeout={200}
            classNames={{
                appearDone: 'is-visible',
                enterDone: 'is-visible',
            }}
            appear
            unmountOnExit
        >
            {Content}
        </CSSTransition>
    );
};

interface NoticeRibbonContentProps {
    containerStyles: string;
    onClick?: () => void;
    handleCloseClick?: () => void;
    children: ReactNode;
    isExpanded: boolean;
    expandableContent: ReactNode;
    onExpandClick: () => void;
    align?: NotceRibbonAlign;
}

const NoticeRibbonContent = React.forwardRef<HTMLDivElement, NoticeRibbonContentProps>(
    (props, ref) => {
        return (
            <div ref={ref} className={props.containerStyles} onClick={props.onClick}>
                <div
                    className={classNames(
                        'Notice-content',
                        props.align === 'left' && 'Notice-content--alignLeft',
                    )}
                >
                    {props.expandableContent && (
                        <button
                            type="button"
                            className="Notice-heading u-outlineDefault u-outlineSolidBase2"
                            onClick={props.onExpandClick}
                            aria-label="Visa mer"
                        >
                            {props.children}
                            <Icon
                                icon={ChevronDownIcon}
                                rotateClockwise={props.isExpanded ? '180deg' : '0deg'}
                            />
                        </button>
                    )}

                    {!props.expandableContent &&
                        (typeof props.children === 'string' ? (
                            <div className="Notice-heading">{props.children}</div>
                        ) : (
                            props.children
                        ))}

                    {props.handleCloseClick && (
                        <button
                            onClick={props.handleCloseClick}
                            type="button"
                            name="closebutton"
                            className="Notice-close"
                            aria-label="Stäng"
                        >
                            <Icon icon={CloseIcon} />
                        </button>
                    )}
                </div>

                {props.expandableContent && props.isExpanded && (
                    <div className="Notice-expandedContent">{props.expandableContent}</div>
                )}
            </div>
        );
    },
);

export default NoticeRibbon;
